import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Id', sortable: true },
    { key: 'name', label: 'Nombre', sortable: true },
    {
      key: 'email',
      label: 'email',
      sortable: true,
    },
    { key: 'status', label: 'Estado', sortable: true },
    { key: 'created_at', label: 'Creado en', sortable: true },
    { key: 'updated_at', label: 'Actualizado en', sortable: true },
    { key: 'actions', label: '' },
  ]
  const perPage = ref(50)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `users.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalUsers.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listUsers = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-user/listUsers', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `users.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalUsers.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 1) return 'info'
    if (role === 2) return 'primary'
    return 'secondary'
  }

  const resolveUserRoleText = role => {
    if (role === 1) return 'Administrador'
    if (role === 2) return 'Operario'
    return 'No role'
  }

  const resolveUserStatusVariant = status => {
    if (status === 2) return 'warning'
    if (status === 1) return 'success'
    if (status === 3) return 'secondary'
    return 'primary'
  }

  const resolveUserStatusText = status => {
    if (status === 2) return 'pendiente'
    if (status === 1) return 'activo'
    if (status === 3) return 'inactivo'
    return '-'
  }

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleText,
    resolveUserStatusVariant,
    refetchData,
    resolveUserStatusText,
    parseDate,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    listUsers,
  }
}
