<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Usuario</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group label="Nombre completo" label-for="name">
              <b-form-input
                id="name"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trimplan-filter
                placeholder="john@email.com"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone Number -->
          <validation-provider
            #default="validationContext"
            name="Telefono"
            rules="required"
          >
            <b-form-group label="Telefono" label-for="phoneNumber">
              <b-input-group>
                <b-input-group-prepend>
                 <b-button variant="dark" disabled>+34</b-button>
                </b-input-group-prepend>
                <b-form-input
                  id="phoneNumber"
                  v-model="userData.phone_number"
                  :state="getValidationState(validationContext)"
                  trimplan-filter
                  placeholder="000 00 00 00"
                />

                
              </b-input-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            :rules="userData.id ? '' : 'required'"
          >
            <b-form-group label="Contraseña" label-for="password">
              <b-input-group>
                <b-form-input
                  :disabled="userData.id && !newPassword ? true : false"
                  id="password"
                  v-model="userData.password"
                  :state="getValidationState(validationContext)"
                  trimplan-filter
                  :placeholder="userData.id && !newPassword ? '***********' : 'Contraseña'"
                />

                <b-input-group-append>
                  <b-button
                      :variant="copied ? 'outline-success' : 'outline-secondary'"
                    @click="copyToClipBoard()"
                    :disabled="userData.id && !newPassword ? true : false"
                  >
                    <feather-icon
                      :icon="copied ? 'CheckSquareIcon' : 'CopyIcon'"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <b-button
                size="sm"
                block
                variant="outline-primary"
                class="mt-1"
                @click="generateRandomPassword()"
                >Generar contraseña aleatoria</b-button
              >
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="user-role"
            rules="required"
          >
            <b-form-group
              label="Rol del usuario"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.fk_role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
                placeholder="Seleccione"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Status -->
          <validation-provider
            #default="validationContext"
            name="user-status"
            rules="required"
          >
            <b-form-group
              label="Estado"
              label-for="user-status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="users.estado"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
                placeholder="Seleccione"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Departamentos -->
          <validation-provider
            #default="validationContext"
            name="departamentos"
            rules="required"
          >
            <b-form-group
              label="Departamentos"
              label-for="departamentos"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-if="departamentos"
                v-model="userData.departamentos"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departamentos"
                :clearable="false"
                input-id="departamentos"
                placeholder="Seleccione"
                multiple
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { users } from "@/utils/users";
import departamentoStoreModule from "@/views/system/departamento/departamentoStoreModule";
import useDepartamentosList from "@/views/system/departamento/useDepartamentosList";
import { v4 } from "uuid";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      users,
      copied: false,
      newPassword: false,
    };
  },
  watch: {
    editItem() {
      if (this.editItem) {
        this.userData = { ...this.editItem };
        this.userData.fk_role = this.roleOptions.find(
          (el) => el.value === this.userData.fk_role.toString()
        );
      }
    },
  },
  methods: {
    generateRandomPassword() {
      this.newPassword = true
      const p = v4();
      this.userData.password = p.toString().substring(0, 8);
    },
    copyToClipBoard() {
      var copyText = document.getElementById("password");
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      navigator.clipboard.writeText(copyText.value);
      this.copied = true;
      setTimeout(() => {
        this.copied = false
      }, 1000);
    },
  },
  setup(props, { emit }) {
    const DEPARTAMENTO_APP_STORE_MODULE_NAME = "app-departamento";

    if (!store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        DEPARTAMENTO_APP_STORE_MODULE_NAME,
        departamentoStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEPARTAMENTO_APP_STORE_MODULE_NAME);
    });

    const departamentos = ref(null);

    const { listDepartamentos } = useDepartamentosList();

    listDepartamentos().then((response) => {
      departamentos.value = response;
    });

    const blankUserData = {
      name: "",
      email: "",
      fk_role: "",
      status: 2,
      password: "",
    };
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      if (userData.value.fk_role.value)
        userData.value.fk_role = userData.value.fk_role.value;
      store.dispatch("app-user/addUser", userData.value).then(() => {
        emit("refetch-data");
        emit("update:is-add-new-user-sidebar-active", false);
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      listDepartamentos,
      departamentos,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
